<template>
  <div class="page_">
    <div class="navbar_">
      <div class="main_">
        <img src="../../assets/medilink/member/header.png" alt="" />
        <div class="text_">
          <p>{{ $t('PAS1[21]') || '会员服务' }}</p>
        </div>
      </div>
      <navbar :showHeader="showHeader" :nowIndex="4"></navbar>
    </div>
    <div class="block_">
      <p class="tit_">{{ $t('PAS1[23]') || '电话咨询服务' }}</p>
      <div class="con_ w1000">
        <div class="flex_">
          <img src="../../assets/medilink/member/imgl.png" alt="" />
          <div class="flex_r">
            <div class="flex_i" v-for="(itemi, i) in iconList" :key="i">
              <img
                :src="itemi.imgUrl"
                alt=""
                :style="{ width: itemi.width, height: itemi.height }"
              />
              <span>{{ itemi.text }}</span>
            </div>
          </div>
        </div>
        <div class="flex_1">
          <div class="flex_l_">
            <img src="../../assets/medilink/member/phone.png" alt="" />
            <div>
              <span>400 880 8820</span>
              <span>{{ $t('PAS2[6]') || '电话咨询服务' }}7*24*365</span>
            </div>
          </div>
          <div class="flex_r_">
            <p>
              <span class="icon_"></span>{{ $t('PAS2[7]') || '15秒内电话应答' }}
              <span class="green_">&gt;98%</span>
            </p>
            <p>
              <span class="icon_"></span>{{ $t('PAS2[8]') || '未接通率' }}
              <span class="green_">&lt;5%</span
              >{{ $t('PAS2[15]') || '（两小时内回拨）' }}
            </p>
            <p>
              <span class="icon_"></span>{{ $t('PAS2[9]') || '单通电话处理率'
              }}<span class="green_">&gt;90%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="block_">
      <p class="tit_">{{ $t('PAS2[10]') || '医院驻场服务' }}</p>
      <div class="con_ w1000">
        <div class="flex_2">
          <div class="flex_i" v-for="(itemi, i) in iconList1" :key="i">
            <img
              :src="itemi.imgUrl"
              alt=""
              :style="{ width: itemi.width, height: itemi.height }"
            />
            <span>{{ itemi.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="block_">
      <p class="tit_">{{ $t('PAS1[21]') || '驻点医院列表' }}</p>
      <div class="con_ w1000">
        <div class="flex_4">
          <div class="flex_i" v-for="(item2, i) in iconList2" :key="i">
            <img :src="item2.imgUrl" alt="" />
            <span
              :style="{
                position: $i18n.locale == 'en-us' ? 'relative' : 'absolute'
              }"
              >{{ item2.text }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="block_">
      <p class="gzh_tit_ tit_">{{ $t('PAS2[12]') || '公众号服务' }}</p>
      <p class="l_tit_">
        {{
          $t('PAS2[13]') ||
            '您可以通过搜索微信公众号“中间带在线”或扫描下方“中间带在线”二维码关注我们享受在线服务'
        }}
      </p>
      <div class="con_ w1000">
        <div class="flex_3">
          <div class="flex">
            <img src="../../assets/medilink/member/hy.png" alt="" />
            <span>{{ $t('PAS2[36]') || '会员中心' }}</span>
          </div>
          <div class="flex">
            <img src="../../assets/medilink/member/lp.png" alt="" />
            <span>{{ $t('PAS2[37]') || '理赔业务办理' }}</span>
          </div>
          <div class="flex">
            <img src="../../assets/medilink/member/yl.png" alt="" />
            <span>{{ $t('PAS2[38]') || '医疗机构查询' }}</span>
          </div>
          <div class="flex">
            <img src="../../assets/medilink/member/lx.png" alt="" />
            <span>{{ $t('PAS2[39]') || '医疗路线导航' }}</span>
          </div>
          <div class="flex">
            <img src="../../assets/medilink/member/wt.png" alt="" />
            <span>{{ $t('PAS2[40]') || '常见问题' }}</span>
          </div>
        </div>
      </div>
    </div>
    <footerbar></footerbar>
    <sideBar v-if="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from './components/footer.vue'
import navbar from './components/navbar.vue'
import sideBar from './components/sideBar.vue'
export default {
  data() {
    return {
      showHeader: false,
      height: 0,
      iconList: [
        {
          imgUrl: require('../../assets/medilink/member/icon1.png'),
          text: this.$t('PAS2[0]') || '专业培训服务团队',
          width: '45px',
          height: '36px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon2.png'),
          text: this.$t('PAS2[1]') || '会员保险福利查询',
          width: '44px',
          height: '32px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon3.png'),
          text: this.$t('PAS2[2]') || '网络医疗机构就诊推荐',
          width: '38px',
          height: '36px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon4.png'),
          text: this.$t('PAS2[3]') || '网络医疗机构预约安排',
          width: '38px',
          height: '38px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon5.png'),
          text: this.$t('PAS2[4]') || '会员有关问题答疑',
          width: '42px',
          height: '42px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon6.png'),
          text: this.$t('PAS2[5]') || '网络医院信息查询',
          width: '43px',
          height: '39px'
        }
      ],
      iconList1: [
        {
          imgUrl: require('../../assets/medilink/member/icon11.png'),
          text: this.$t('PAS2[16]') || '主要网络医院驻点服务',
          width: '44px',
          height: '44px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon22.png'),
          text: this.$t('PAS2[17]') || '会员福利查询',
          width: '42px',
          height: '40px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon33.png'),
          text: this.$t('PAS2[18]') || '身份确认',
          width: '54px',
          height: '33px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon44.png'),
          text: this.$t('PAS2[19]') || '就医指导与语言帮助',
          width: '40px',
          height: '41px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon55.png'),
          text: this.$t('PAS2[20]') || '自付额收取',
          width: '39px',
          height: '39px'
        },
        {
          imgUrl: require('../../assets/medilink/member/icon66.png'),
          text: this.$t('PAS2[21]') || '冲减保额',
          width: '35px',
          height: '39px'
        }
      ],
      iconList2: [
        {
          imgUrl: require('../../assets/medilink/member/h1.jpg'),
          text: this.$t('PAS2[22]') || '北京协和医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h2.jpg'),
          text: this.$t('PAS2[23]') || '上海东方医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h3.jpg'),
          text: this.$t('PAS2[24]') || '上海禾新医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h4.jpg'),
          text: this.$t('PAS2[25]') || '苏州大学附属第一人民医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h5.jpg'),
          text: this.$t('PAS2[26]') || '北京中日友好医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h6.jpg'),
          text: this.$t('PAS2[27]') || '上海瑞金医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h7.jpg'),
          text: this.$t('PAS2[28]') || '复旦大学附属华山医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h8.jpg'),
          text: this.$t('PAS2[29]') || '上海东方医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h9.jpg'),
          text: this.$t('PAS2[30]') || '上海儿童医学中心'
        },
        {
          imgUrl: require('../../assets/medilink/member/h10.jpg'),
          text: this.$t('PAS2[31]') || '北京安贞医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h11.jpg'),
          text: this.$t('PAS2[32]') || '上海华东医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h12.jpg'),
          text: this.$t('PAS2[33]') || '上海仁济医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h13.jpg'),
          text: this.$t('PAS2[34]') || '苏州九龙医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h14.jpg'),
          text: this.$t('PAS2[35]') || '上海龙华医院'
        },
        {
          imgUrl: require('../../assets/medilink/member/h15.jpg'),
          text: ''
        }
      ],
      showBar: false
    }
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll()
    }
  },
  components: {
    footerbar,
    navbar,
    sideBar
  },
  methods: {
    handleScroll() {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      )
      this.height = top
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true
          if (newHeight > oldHeight) {
            this.showHeader = false
          } else {
            this.showHeader = true
          }
        } else {
          this.showHeader = false
          this.showBar = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page_ {
  padding: 0;
  background-color: #fff;
  p {
    margin: 0;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }

  .block_ {
    width: 100%;
    color: #000000;
    font-size: 14px;
    p {
      text-align: center;
    }
    .flex_ {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > .tit_ {
      font-size: 20px;
      font-weight: bold;
      padding: 50px 0;
    }
    .gzh_tit_ {
      padding-bottom: 20px;
    }
    .l_tit_ {
      font-weight: 500;
      color: #000;
      padding-bottom: 50px;
    }
    .drop {
      width: 4px;
      height: 4px;
      background-color: #00a63c;
      display: inline-block;
      margin: 0 16px;
    }
    .bold_ {
      font-weight: bold;
    }
    .con_ {
      color: #333;
      font-size: 14px;
      &.w1000 {
        width: 1000px;
        margin: 0 auto;
        .flex_ {
          justify-content: space-between;
          & > img {
            width: 441px;
            height: 307px;
          }
          .flex_r {
            width: 550px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .flex_i {
              padding-left: 8px;
              width: 178px;
              height: 149px;
              background: url(../../assets/medilink/member/imgbg.png) center
                center no-repeat;
              background-size: 178px 149px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-bottom: 8px;
              &:nth-child(n + 4) {
                margin-bottom: 0;
              }
              img {
                margin-bottom: 15px;
              }
            }
          }
        }
        .flex_1 {
          display: flex;
          align-items: center;
          background-color: #f8f8f8;
          padding: 24px 48px;
          justify-content: space-between;
          margin-top: 8px;
          .flex_l_ {
            display: flex;
            align-items: center;
            img {
              width: 48px;
              height: 48px;
              object-fit: cover;
              margin-right: 18px;
            }
            div {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 14px;
              color: #666;
              span:first-child {
                font-weight: bold;
                font-size: 20px;
                color: #000;
              }
            }
          }
          .flex_r_ {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #000;
            width: 570px;
            p {
              display: flex;
              align-items: center;
              margin-right: 6px;
            }
            .icon_ {
              width: 13px;
              height: 14px;
              background: url(../../assets/medilink/member/check.png) center
                center no-repeat;
              background-size: 13px 14px;
              display: inline-block;
              margin-right: 8px;
            }
            .green_ {
              color: #00a63c;
            }
          }
        }
        .flex_2 {
          display: flex;
          flex-wrap: wrap;

          .flex_i {
            width: 33.33%;
            height: 198px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            color: #000;
            &:nth-child(2n + 1) {
              background-color: #fdfdfd;
            }
            &:nth-child(2n + 2) {
              background-color: #f9f9f9;
            }
            img {
              object-fit: cover;
              margin-bottom: 15px;
            }
          }
        }
        .flex_3 {
          display: flex;
          justify-content: space-between;
          padding-bottom: 50px;
          margin-bottom: 83px;
          .flex {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > img {
              width: 180px;
              height: 382px;
            }
            & > span {
              padding-top: 10px;
              color: #666;
              font-size: 14px;
            }
          }
        }
        .flex_4 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .flex_i {
            width: 20%;
            min-width: 20%;
            position: relative;
            img {
              width: 100%;
              height: auto;
            }
            span {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 7px;
              margin: 0 auto;
              text-align: center;
              font-size: 15px;
              color: #000;
            }
          }
        }
      }
    }
  }
}
</style>
